<template>
    <div class="form-smart-text">
        <div
            :id="id"
            ref="input"
            v-bind="$attrs"
            :class="[ textareaClass, { 'form-input--initial': isInitial } ]"
            :contenteditable="!disabled"
            :disabled="disabled"
            :required="required"
            :autofocus="autofocus"
            :tabindex="disabled ? -1 : tabindex"
            class="form-textarea form-smart-text__input form-smart-text__input--textarea"
            @blur="blur"
            @change="$emit('change', getPlainTextValue($event.target.innerHTML))"
            @contextmenu="$event.preventDefault()"
            @focus="focus"
            @input="$emit('input', getPlainTextValue($event.target.innerHTML))"
            @keydown="closeLinkContextMenu"
        />
        <div
            v-if="showPlaceholder"
            :class="{'form-smart-text__placeholder--disabled': disabled}"
            class="form-smart-text__placeholder"
        >
            {{ placeholder }}
        </div>
        <textarea
            :disabled="disabled"
            :name="name"
            :required="required"
            :value="value"
            autocomplete="off"
            class="form-smart-text__hidden"
            tabindex="-1"
        />
        <button
            :aria-label="buttonTooltip"
            :disabled="disabled || loading"
            class="form-smart-text__button hint--left"
            type="button"
            tabindex="-1"
        >
            <frm-icon
                :class="{'form-smart-text__button-icon--error': error}"
                :name="buttonIconName"
                class="form-smart-text__button-icon"
            />
        </button>
    </div>
</template>

<script>
    import S from 'string';
    import DataText from './DataText.vue';

    export default {
        extends: DataText,

        props: {
            id: {
                type: String,
                default() {
                    return 'smart_textarea_' + this.uniqueIdentifier;
                },
            },
        },

        methods: {
            initSpecialBehaviour() {
                // No special behaviour needed for this input
            },

            /**
             * @param {string} text
             *
             * @return {string}
             */
            getPlainTextFromPaste(text) {
                return text;
            },

            /**
             * @param {string} value
             *
             * @returns {string}
             */
            getPlainTextValue(value) {
                return S(value)
                    .replace(/<div><br\/?><\/div>/g, '<div></div>')
                    .replace(/<\/div>/g, '</div>\n')
                    .replace(/<br\/?>/g, '\n')
                    .trim()
                    .stripTags()
                    .stripRight('\n')
                    .decodeHTMLEntities()
                    .toString();
            },
        },
    };
</script>
