import iconRenderer from './iconRenderer';
import $ from 'jquery';
import 'jquery-contextmenu';
import 'jquery-ui/ui/position';

export const TYPE_DEFAULT = 'defaultWithSvgIcon';
export const TYPE_SUBMENU = 'submenuWithSvgIcon';

/**
 * @param {Array} actions
 * @param {Function} [actionCallback]
 * @param {Function} [actionDisabledCallback]
 * @param {Function} [actionVisibleCallback]
 *
 * @returns {Array}
 */
export default function buildContextMenuItemsFromActions(actions, actionCallback, actionDisabledCallback, actionVisibleCallback) {
    const items = [];

    actions.forEach((action) => {
        if (action.type === 'break') {
            items.push('---------');
        } else {
            items.push(buildItemFromAction(action, actionCallback, actionDisabledCallback, actionVisibleCallback));
        }
    });

    return items;
}

export function removeFirstAndLastSeparator(menu) {
    const $items = menu.find('.context-menu-item:visible');

    if ($items.first().is('.context-menu-separator')) {
        $items.first().remove();
    }

    if ($items.last().is('.context-menu-separator')) {
        $items.last().remove();
    }
}

/**
 * @param {{label: String, [icon]: String, [disabled]: Boolean|Function, [visible]: Boolean|Function, [id]: String, [items]: Array}} action
 * @param {Function} [actionCallback]
 * @param {Function} [actionDisabledCallback]
 * @param {Function} [actionVisibleCallback]
 *
 * @returns {{name: String, type: String, disabled: Boolean, [id]: String, [svgIcon]: String, [items]: Array}}
 */
function buildItemFromAction(action, actionCallback, actionDisabledCallback, actionVisibleCallback) {
    const item = {
        name: action.label,
        type: TYPE_DEFAULT,
        svgIcon: action.icon,
        disabled: action.disabled,
        visible: action.visible,
    };

    if (action.id) {
        item.id = action.id;
    }

    // Handle Group
    if ('items' in action && action.items.length > 0) {
        item.type = TYPE_SUBMENU;
        item.items = [];

        action.items.forEach((groupAction) => {
            if (groupAction.type === 'break') {
                item.items.push('---------');
            } else {
                item.items.push(buildItemFromAction(groupAction, actionCallback, actionDisabledCallback, actionVisibleCallback));
            }
        });
    } else {
        if (actionCallback) {
            item.callback = function (key, opt, event) {
                return actionCallback(action, this, key, opt, event);
            };
        }
    }

    if (item.disabled === undefined && actionDisabledCallback) {
        item.disabled = function (key, opt) {
            return actionDisabledCallback(action, this, key, opt);
        };
    }

    if (item.visible === undefined && actionVisibleCallback) {
        item.visible = function (key, opt) {
            return actionVisibleCallback(action, this, key, opt);
        };
    }

    return item;
}

/**
 * SVG icon for context menu items
 *
 * @param {{name: String, svgIcon: String}} item
 * @param opt
 * @param root
 */
$.contextMenu.types[TYPE_DEFAULT] = function (item, opt, root) {
    if (item.svgIcon) {
        $(iconRenderer(item.svgIcon, 'context-menu-item__icon '))
            .appendTo(this);
    }

    $('<span />')
        .text(item.name)
        .appendTo(this);
};

/**
 * SVG icon for context submenu items.
 * N.B. This is copied and slightly altered from the original jQuery-contextMenu.
 *
 * @param {{name: String, svgIcon: String, appendTo, $node}} item
 * @param opt
 * @param root
 *
 * @see https://github.com/swisnl/jQuery-contextMenu/blob/605de5e18ce21ccace02c1cac53d62d37e5581bf/src/jquery.contextMenu.js#L1288
 */
$.contextMenu.types[TYPE_SUBMENU] = function (item, opt, root) {
    $.contextMenu.types[TYPE_DEFAULT].call(this, item, opt, root);

    item.appendTo = item.$node;
    item.callback = null;

    this.data('contextMenu', item).addClass('context-menu-submenu');

    $.contextMenu.op.create(item, root);
};
