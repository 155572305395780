<template>
    <button type="button" :class="btnClass" @click.prevent.stop="openModal()">
        <slot />
    </button>
</template>

<script>
import ModalManager from '../services/modalManager';

export default {
    props: {
        url: {
            type: String,
            default: '',
        },

        size: {
            type: String,
            default: undefined,
        },

        print: {
            type: Boolean,
            default: false,
        },

        btnClass: {
            type: String,
            default: 'button button--secondary',
        },
    },

    methods: {
        async openModal() {
            let result;

            if (this.$slots['modal-body']) {
                result = await ModalManager.openVNodesInModal(this.$slots['modal-body'], this.size, true);
            } else {
                result = await ModalManager.openModal(this.url, undefined, undefined, this.size, true, this.print);
            }

            this.$emit('closed', result);
        },
    },
};
</script>
